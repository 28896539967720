.splash-text {
	text-align:center;
	position: absolute;
	width:100vw;
	margin: 2rem 0 0 0;
	z-index: 1;
	color:white;
	letter-spacing: 0.4rem;
}
.splash-text img {
	width: 10rem;
}

button, a {
	cursor: pointer !imporant;
}

.notify-frame {
	width:100%;
	height: 80vh;
}
