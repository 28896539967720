.splash-text {
	text-align:center;
	position: absolute;
	width:100vw;
	margin: 2rem 0 0 0;
	z-index: 1;
	color:white;
	letter-spacing: 0.4rem;
}
.splash-text img {
	width: 10rem;
}

button, a {
	cursor: pointer !imporant;
}

.dream-header {
	border: 1px solid #ddf;
	border-bottom: none;
	border-top-left-radius: 8px;
	border-top-right-radius: 8px;
}

.dream-footer {
	border: 1px solid #ddf;
	border-top: none;
	border-bottom-left-radius: 8px;
	border-bottom-right-radius: 8px;
}

.horoscope-body {
	background: #fdfdff;
}

.my-pre {
	display:block;
	font-family: monospace;
	white-space: pre-wrap;
	overflow-wrap : break-word;
}
